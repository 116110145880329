/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { camelizeKeys } from 'humps';
import type { RootStateOrAny } from 'react-redux';
import { validateTestServer } from '../utils';

const appendQueryStringParam = (args: string | FetchArgs, key: string, value: string): string | FetchArgs => {
  let urlEnd = typeof args === 'string' ? args : args.url;

  if (urlEnd.indexOf('?') < 0) {
    urlEnd += '?';
  } else {
    urlEnd += '&';
  }

  urlEnd += `${key}=${value}`;

  return typeof args === 'string' ? urlEnd : { ...args, url: urlEnd };
};

const rawBaseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl,
  mode: 'cors',
  prepareHeaders: (headers, api) => {
    const { token } = (api.getState() as RootStateOrAny).auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Accept', 'application/json');
    }
    return headers;
  },
  timeout: 60000,
});

const customBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  let baseUrl = (api.getState() as RootStateOrAny).app.route;
  const { providerMode } = (api.getState() as RootStateOrAny).auth;

  let customArgs = args;
  const domain = customArgs.body?.domain;

  if (domain) {
    baseUrl = validateTestServer(domain);
  }

  if (process.env.NODE_ENV === 'test') {
    baseUrl = 'http://localhost';
  }

  if (providerMode) {
    customArgs = appendQueryStringParam(customArgs, 'provider_tracker', 'true');
  }

  if (baseUrl === undefined) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No base url received',
      },
    };
  }

  const result = await rawBaseQuery(baseUrl)(customArgs, api, extraOptions);

  if (result.data) {
    result.data = camelizeKeys(result.data);
  }

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'api',
});
