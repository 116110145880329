import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./seamless/ar.json";
import en from "./seamless/en.json";
import es from "./seamless/es.json";
import fr from "./seamless/fr.json";
import it from "./seamless/it.json";
import hi from "./seamless/hi.json";
import pa from "./seamless/pa.json";
import pl from "./seamless/pl.json";
import pt from "./seamless/pt.json";
import ur from "./seamless/ur.json";
import sunnybrookEN from "./sunnybrook/sunnybrookEN.json";

const resources = {
  ar: { seamless: ar },
  en: {
    seamless: en,
    sunnybrook: sunnybrookEN,
  },
  es: { seamless: es },
  fr: { seamless: fr },
  it: { seamless: it },
  hi: { seamless: hi },
  pa: { seamless: pa },
  pl: { seamless: pl },
  pt: { seamless: pt },
  ur: { seamless: ur },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // have a common namespace used around the full app
    ns: ["seamless", "sunnybrook"],
    defaultNS: "seamless",
    fallbackNS: "seamless",
  });

export default i18n;
