// The authSlice follows the example in RTK documentation
// https://redux-toolkit.js.org/rtk-query/usage/examples#using-extrareducers

import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../api/authApi';
import { patientApi } from '../api/patientApi';

type AuthState = {
  demo: boolean,
  patientId: string | null,
  providerMode: boolean,
  token: string | null,
};

const initialState = {
  demo: false,
  patientId: null,
  providerMode: false,
  token: null,
} as AuthState;

export const logout = createAction('auth/logout');

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProviderMode: (
      state,
      { payload: providerMode }: PayloadAction<boolean>,
    ) => {
      state.providerMode = providerMode;
    },
    setToken: (
      state,
      { payload: token }: PayloadAction<string>,
    ) => {
      state.token = token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.token = null;
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
        },
      )
      .addMatcher(
        authApi.endpoints.setup.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
        },
      )
      .addMatcher(
        authApi.endpoints.signup.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token;
        },
      )
      .addMatcher(
        patientApi.endpoints.getPatient.matchFulfilled,
        (state, { payload }) => {
          state.patientId = `${payload.server}-${payload.uid}`;
          state.demo = payload.demo;
        },
      );
  },
});

export const { setProviderMode, setToken } = slice.actions;

export default slice.reducer;
