import React from 'react';
import ReactDOM from 'react-dom';
import { isIE } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './localization/i18n';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/configureStore';
import * as serviceWorker from './serviceWorker';

// a function to retry loading a chunk to avoid chunk load error for out of date code
function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
      // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
}

let App;

if (isIE) {
  App = React.lazy(() => import('./polyfills'));
} else {
  App = React.lazy(() => lazyRetry(() => import('./App')));
}

// Sentry config
// DSNs are safe to keep public https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/
Sentry.init({
  dsn: 'https://7a252c5af7824ad08c6a10da3fb51bfe@o16484.ingest.sentry.io/5600389',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
